import Card from '@mui/material/Card'
import { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import copyIcon from '../../../../../assets/copy.svg'
import reportIcon from '../../../../../assets/feedback.svg'
import regenerateIcon from '../../../../../assets/regenerate.svg'
import { HistoryContext } from '../../../../../contexts/history'
import Tooltip, { StyledTooltip } from '../../../../../globalComponents/tooltip'
import { useLoading } from '../../../../../globalComponents/useLoading'
import { AssistantApi } from '../../../../../services/AssistantApi.service'
import { messageType } from '../../../../../types/message'
import { COLORS } from '../../../../../utils/colors'
import { copyToClipboard } from '../../../../../utils/copyToClippboard'

const Background = styled.div`
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    background-color: transparent;
`

const FeedbackButton = styled.button`
    padding: 5px 10px;
    background-color: ${COLORS.cinzaDesbotado};
    border: 2px solid ${COLORS.cinzaDesbotado};
    border-radius: 5px;
    display: inline-block;
    width: auto;
    min-width: 100px;
    height: 30px;
    cursor: pointer;
    z-index: 99999999;
    align-self: flex-end;
    color: ${COLORS.cinzaEscuro}; 
`
const FeedbackWrapper = styled.div`
    position: absolute;
    top: calc(100% - 10px);
    background-color: ${COLORS.branco};
    left: 0;
    padding: 8px;
    z-index: 99999999;
    display: flex;
    flex-direction: column;
    gap: 6px;
    border: 2px solid ${COLORS.cinzaDesbotado};
    border-radius: 12px;
    width: 600px;
    margin: 0 auto;
`

const ToolsButton = styled.button`
    position: relative;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 15px;
    margin-left: 0;
    &:hover ${StyledTooltip} {
        visibility: visible;
    }
`
const FeedbackTextArea = styled.textarea`
    padding: 5px;
    outline: 0;
    border-radius: 6px;
    z-index: 99999999;
    position: relative;
    border: 2px solid ${COLORS.cinzaDesbotado};;
    width: 100%;
    min-height: 50px;
    resize: none;
    overflow-y: hidden;
    font-size: 14px;
    line-height: 1.4;
`

const StyledTools = styled.div`
    display: flex;
    position: relative;
    & img {
        width: 15px;
    }
`

const ToastMessage = styled.span`
    font-size: 12px;
    color: ${COLORS.cinzaEscuro};
    position: absolute;
    top: 12px;
    left: 90px;
    cursor: auto;
`

interface ToolsProps {
    message: messageType
    lastAssistantMessage: boolean
}

export default function Tools({ lastAssistantMessage, message }: ToolsProps) {
    const { includeMessageAtArray, selected } = useContext(HistoryContext)
    const { Spinner, setIsLoading, isLoading } = useLoading()
    const toastRef = useRef<HTMLSpanElement>(null)

    const [feedbackMessage, setFeedbackMessage] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [showInput, setShowInput] = useState(false)

    async function handleCopy() {
        if (!toastRef.current) return
        const answer = await copyToClipboard(message.content)
        toastMessage(answer)
    }

    async function handleRegenerate() {
        try {
            setIsLoading(true)
            const api = new AssistantApi()
            const response = await api.regenerateAnswer(message.thread_id)
            includeMessageAtArray(response)
        } catch (error: any) {
            includeMessageAtArray(error)
        } finally {
            setIsLoading(false)
        }
    }

    async function handleReport() {
        if (!selected || !feedbackMessage.trim()) {
            toastMessage('A mensagem de feedback não pode estar vazia.');
            return
        }
        try {
            setIsSubmitting(true)
            setIsLoading(true)
            const api = new AssistantApi()
            const response = await api.reportAnswer(
                feedbackMessage,
                message.guid
            );
            toastMessage('Obrigado pelo feedback.')
            setFeedbackMessage('')
        } catch (error: any) {
            toastMessage('Erro ao registrar feedback. Tente novamente.')
        } finally {
            setIsLoading(false)
            setIsSubmitting(false)
            setShowInput(false)
        }
    }

    function toastMessage(message: string) {
        if (!toastRef.current) return
        toastRef.current.innerHTML = message
        setTimeout(() => {
            if (!toastRef.current) return
            toastRef.current.innerHTML = ''
        }, 2000)
    }

    function handleTextAreaChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        const target = e.target;
        target.style.height = 'auto';
        target.style.height = `${target.scrollHeight}px`;
        setFeedbackMessage(target.value);
        scrollDown();
    }

    function scrollDown() {
        if(lastAssistantMessage)
            document.querySelector('#feedback-anchor')?.scrollIntoView({ block: 'end' })
    }

    useEffect(() => {
        if(showInput == true)
        {
            scrollDown()
            console.log(showInput)
        }
    }, [showInput])

    return (
        <StyledTools>
            <Spinner />
            <ToolsButton disabled={isLoading} onClick={handleCopy}>
                <Tooltip left="0" top="-25px" text="Copiar" />

                <img
                    src={copyIcon}
                    alt="Ícone copiar para área de transferência"
                />
            </ToolsButton>
            {lastAssistantMessage && (
                <ToolsButton disabled={isLoading} onClick={handleRegenerate}>
                    <Tooltip left="0" top="-25px" text="Nova resposta" />
                    <img
                        src={regenerateIcon}
                        alt="Ícone para gerar novamente"
                    />
                </ToolsButton>
            )}

            {
                <ToolsButton
                    disabled={isLoading}
                    onClick={() => {
                        setShowInput((prev) => !prev)
                    }}
                >
                    <Tooltip left="0" top="-25px" text="Reportar erro" />

                    <img src={reportIcon} alt="Ícone para reportar o texto" />
                </ToolsButton>
            }

            {showInput && (
                <Card>
                    <FeedbackWrapper>
                        <Background onClick={() => setShowInput(false)} />
                        <FeedbackTextArea
                            autoFocus
                            value={feedbackMessage}
                            placeholder="O que deseja reportar?"
                            onChange={handleTextAreaChange}
                        />
                        <FeedbackButton type="button" onClick={handleReport} disabled={isSubmitting}>
                            {isSubmitting ? 'Enviando...' : 'Enviar Feedback'}
                        </FeedbackButton>
                        <div id="feedback-anchor"></div>
                    </FeedbackWrapper>
                </Card>
            )}
            
            <ToastMessage ref={toastRef}></ToastMessage>
        </StyledTools>
    )
}
