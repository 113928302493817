import React, {
    Dispatch,
    SetStateAction,
    createContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { threadType } from '../types/thread'
import { AssistantApi } from '../services/AssistantApi.service'
import { messageType } from '../types/message'
import { TribunaisType } from '../types/tribunais'
import { AssistantType } from '../types/assistant'
import { CookiesService } from '../services/Cookie.service'
import { limitsType } from '../types/limits'

const limitsTemplate = {
    current_answers: 0,
    current_assistants: 0,
    current_users: 0,
    max_answers: 0,
    max_assistants: 0,
    max_users: 0,
}

interface SubscriptionType {
    created_at: string
    guid: string
    issued_at: string
    exp_at: string
    is_active: boolean
    price: number
    stripe_subscription_id: string
    pending_activation: boolean
}
interface IHistoryContext {
    selected: threadType | null
    setSelected: Dispatch<SetStateAction<threadType | null>>
    historyArray: threadType[]
    createNewChat: () => Promise<void>
    messagesArray: messageType[]
    includeMessageAtArray: (message: messageType) => void
    tribunal: TribunaisType
    setTribunal: Dispatch<SetStateAction<TribunaisType>>
    assistant: AssistantType | null
    setAssistant: Dispatch<SetStateAction<AssistantType | null>>
    limits: limitsType
    assistants: AssistantType[]
    getLimits: () => void
    archiveThread: (guid: string) => void
    deleteThread: (guid: string) => void
    updateCurrentThread: (message: messageType) => void
    subscription: SubscriptionType | null
    alertVisibility: string
}

export const HistoryContext = createContext<IHistoryContext>({
    selected: { owner_id: '0', guid: '0', name: '', created_at: '' },
    setSelected: () => {},
    historyArray: [],
    createNewChat: () => {
        return Promise.resolve()
    },
    messagesArray: [],
    includeMessageAtArray: () => {},
    tribunal: 'Tribunais Superiores',
    setTribunal: () => {},
    assistant: null,
    setAssistant: () => {},
    limits: limitsTemplate,
    assistants: [],
    subscription: null,
    getLimits: () => {},
    archiveThread: () => {},
    deleteThread: () => {},
    updateCurrentThread: () => {},
    alertVisibility: 'transparent',
})

const HistoryProvider = ({
    children,
}: {
    children: React.ReactNode
}): JSX.Element => {
    const [selected, setSelected] = useState<threadType | null>(null)
    const [assistants, setAssistants] = useState<AssistantType[]>([])
    const [assistant, setAssistant] = useState<AssistantType | null>(null)
    const [historyArray, setHistoryArray] = useState<threadType[]>([])
    const [messagesArray, setMessagesArray] = useState<messageType[]>([])
    const [limits, setLimits] = useState<limitsType>(limitsTemplate)
    const [tribunal, setTribunal] = useState<TribunaisType>(
        'Tribunais Superiores'
    )
    const [subscription, setSubscription] = useState<SubscriptionType | null>(
        null
    )
    const [alertVisibility, setAlertVisibility] =
        useState<string>('transparent')
    const api = useMemo(() => new AssistantApi(), [])

    async function getSubscription() {
        try {
            const res = await api.getCurrentSubscription()
            setSubscription(res)
        } catch (error) {
            console.error('Erro ao obter subscription:', error)
        }
    }

    async function createNewChat(forceCreate: boolean = false): Promise<void> {
        if (!forceCreate && historyArray.find((item) => item.guid === 'new')) {
            if (
                historyArray.findIndex(
                    (item) => item.guid === selected?.guid
                ) === 0
            ) {
                triggerAlertVisibility()
            } else
                setSelected(
                    historyArray[
                        historyArray.findIndex((item) => item.guid === 'new')
                    ]
                )
            return
        }
        const newChat: threadType = {
            created_at: new Date(),
            guid: 'new',
            name: 'Novo Chat',
            owner_id: '',
        }
        setHistoryArray((prev) => [newChat, ...prev])
        setSelected(newChat)
        return
    }

    async function archiveThread(guid: string) {
        await api.archiveThread(guid)
        await getThreads()
    }
    async function deleteThread(guid: string) {
        await api.deleteThread(guid)
        await getThreads()
    }

    function updateHistoryArrayAtIndex(index: number, updatedChat: threadType) {
        if (index !== -1) {
            setHistoryArray((prev) => {
                const newArray = [...prev]
                newArray[index] = updatedChat
                return newArray
            })
        }
    }

    async function updateCurrentThread(message: messageType) {
        var threadName = 'Novo Chat'
        if (selected?.guid === 'new' && message.thread_id !== 'new') {
            const thread = await api.getThread(message.thread_id)

            threadName = thread.name
        }
        const updatedChat: threadType = {
            created_at: new Date(),
            guid: message.thread_id,
            name: threadName,
            owner_id: '',
        }
        setSelected(updatedChat)
        const newThreadIndex = historyArray.findIndex(
            (item) => item.guid === 'new'
        )
        updateHistoryArrayAtIndex(newThreadIndex, updatedChat)
    }

    function includeMessageAtArray(message: messageType) {
        setMessagesArray((prev) => [...prev, message])
        updateCurrentThread(message)
    }

    function triggerAlertVisibility() {
        setAlertVisibility('red')
        setTimeout(() => {
            setAlertVisibility('transparent')
        }, 1500)
    }

    async function getMessages() {
        if (selected?.guid && api) {
            api.getMessages(selected?.guid)
                .then((response) => {
                    response?.length === 0
                        ? setMessagesArray([
                              {
                                  content: 'Olá, como posso ajudar?',
                                  thread_id: '',
                                  assistant_id: 'schief',
                                  author_id: '',
                                  created_at: new Date(),
                                  guid: '',
                              },
                          ])
                        : setMessagesArray(response)
                })
                .catch((err) => {
                    setMessagesArray([
                        {
                            content: 'Olá, como posso ajudar?',
                            thread_id: '',
                            assistant_id: 'schief',
                            author_id: '',
                            created_at: new Date(),
                            guid: '',
                        },
                    ])
                })
        } else {
            setMessagesArray([])
        }
    }

    async function getLimits() {
        return api
            .getLimits()
            .then((response: limitsType) => setLimits(response))
    }

    async function getThreads() {
        return api.getThreads().then((response: threadType[]) => {
            setHistoryArray(response)
            createNewChat(true)
        })
    }

    async function getAssistants() {
        return api.getAssistants().then((res) => {
            setAssistants(res)
            const cookieService = new CookiesService()
            const c_assistant = cookieService.getAssistant()
            if (c_assistant) {
                setAssistant(c_assistant)
            } else {
                setAssistant(res[0])
            }
        })
    }

    useEffect(() => {
        getMessages()
    }, [selected])

    useEffect(() => {
        getThreads()
        getLimits()
        getAssistants()
        getSubscription()
    }, [api])

    return (
        <HistoryContext.Provider
            value={{
                selected,
                setSelected,
                historyArray,
                createNewChat,
                messagesArray,
                includeMessageAtArray,
                tribunal,
                setTribunal,
                assistant,
                setAssistant,
                limits,
                assistants,
                getLimits,
                archiveThread,
                deleteThread,
                updateCurrentThread,
                subscription,
                alertVisibility,
            }}
        >
            {children}
        </HistoryContext.Provider>
    )
}

export default HistoryProvider
