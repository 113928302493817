import { useContext } from 'react'
import styled from 'styled-components'
import { COLORS } from '../../../../../utils/colors'
import { HistoryContext } from '../../../../../contexts/history'
import ListItem from './ListItem'

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`
const Title = styled.h2`
    font-size: 20px;
    font-weight: 400;
    margin: 15px 0;
    text-align: center;
    color: ${COLORS.cinzaEscuro};
`

const List = styled.ul`
    list-style: none;
    max-height: 300px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export default function History() {
    const { historyArray } = useContext(HistoryContext)

    return (
        <Container>
            <Title>Histórico</Title>
            <List>
                {historyArray?.map((item) => (
                    <ListItem item={item} key={item.guid} />
                ))}
            </List>
        </Container>
    )
}
