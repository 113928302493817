import styled from 'styled-components'
import {COLORS} from '../../../../utils/colors'
import logo from '../../../../assets/logo.png'
import History from './history/history'
import user from '../../../../assets/user.svg'
import {useContext, useState, useEffect, useRef} from 'react'
import HideShowBarButton from '../../../../globalComponents/hideShowBarButton'
import Button from '../../../../globalComponents/ButtonPattern'
import {AuthContext} from '../../../../contexts/auth'
import NavList from './navList'
import {HistoryContext} from '../../../../contexts/history'
import createNewIcon from '../../../../assets/createNew.svg'

const Section = styled.section<{show: boolean}>`
    background-color: ${COLORS.cinzaClaro};
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0px 44px 0;
    justify-content: space-between;
    position: relative;
    transform: translateX(${({show}) => (show ? '0' : '-100%')});
    transition: all 0.3s;
    z-index: 55;

    @media (max-width: 640px) {
        position: absolute;
        min-height: 100vh;
    }
`
const Logo = styled.img`
    width: 200px;
`

const ListWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;
`

interface Props {
    visibility: string
}

const Alert = styled.div<Props>`
    color: ${(props) => props.visibility};
    transition: color 0.25s;
`

export default function LeftBar() {
    const [show, setShow] = useState(true)
    const {userInfo} = useContext(AuthContext)
    const {createNewChat, limits, alertVisibility} = useContext(HistoryContext)
    const [openList, setOpenList] = useState(false)

    const listRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                listRef.current &&
                !listRef.current.contains(event.target as Node)
            ) {
                setOpenList(false)
            }
        }

        if (openList) {
            document.addEventListener('mousedown', handleClickOutside)
        } else {
            document.removeEventListener('mousedown', handleClickOutside)
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [openList])

    return (
        <Section show={show}>
            <Wrapper>
                <Logo src={logo} alt="Logo Schief.ai" />
                <Button
                    onclick={createNewChat}
                    IconAltText="Criar novo chat"
                    text="Novo Chat"
                    SVGIcon={createNewIcon}
                />
                <Alert visibility={alertVisibility}>
                    Você já está em um novo chat!
                </Alert>
            </Wrapper>
            <History />
            <ListWrapper ref={listRef}>
                {openList && <NavList />}
                <Button
                    onclick={() => setOpenList((prev) => !prev)}
                    IconAltText="Abrir Lista de Configurações"
                    text={`${userInfo.first_name}`}
                    SVGIcon={user}
                    endText={`${limits.current_answers}/${limits.max_answers} `}
                    tooltip="Conta"
                />
            </ListWrapper>

            <HideShowBarButton
                position="right"
                offset="-20px"
                show={show}
                setShow={setShow}
            />
        </Section>
    )
}
